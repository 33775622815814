import { createApp } from 'vue';
import { createDesignSystem } from '@infusionsoft/design-system';
import components from '@infusionsoft/design-system/src/components';

import App from '@/App';
import { createRouter } from '@/router';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { useGlobalStore } from '@/stores/global';
import { handlePromiseRejections, handleError } from '@/utils/error.util';
import { setupInterceptors } from '@/utils/interceptor.util';
import VueClipboard from 'vue-clipboard2';
import '@/styles/main.scss';

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

app.use(pinia);

const router = createRouter();
const designSystem = createDesignSystem({
    components,
    useNewIllustrations: true,
    onOverlayActive: () => { useGlobalStore().activateOverlay(); },
    onOverlayInactive: () => { useGlobalStore().deactivateOverlay(); },
});

setupInterceptors(router);
app.config.errorHandler = handleError;
handlePromiseRejections();

app.use(designSystem);
app.use(router);
app.use(VueClipboard);

app.mount('#app');
