import { defineStore } from 'pinia';

// from https://stackoverflow.com/a/38552302/4347140
const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`;
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const useAuthStore = defineStore('auth', {
    persist: true,

    state: () => ({
        casJwt: '',
        user: {},
        userId: '',
    }),

    actions: {
        setCasJwt(jwt) {
            this.casJwt = jwt;
        },

        login({ casJwt }) {
            this.setCasJwt(casJwt);

            if (casJwt) {
                const user = parseJwt(casJwt);

                this.setUser(user);
            }
        },

        logout() {
            this.setCasJwt('');
            this.setUser({
                name: '',
                email: '',
            });
        },

        setUser(user) {
            this.user = user;
            this.userId = user ? user.email : '';
        },
    },
});
