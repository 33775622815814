const FeatureFlags = () => import(/* webpackChunkName: "flags" */ '../views/flags/FeatureFlags.vue');
const ThresholdReport = () => import(/* webpackChunkName: "report" */ '../views/reports/ThresholdReport.vue');
const RecentlyCreatedReport = () => import(/* webpackChunkName: "report" */ '../views/reports/RecentlyCreatedReport.vue');
const RecentlyChangedReport = () => import(/* webpackChunkName: "report" */ '../views/reports/RecentlyChangedReport.vue');
const FeatureFlagDescription = () => import(/* webpackChunkName: "flags" */ '../views/flags/FeatureFlagDescription.vue');
const FlagGroups = () => import(/* webpackChunkName: "groups" */ '../views/groups/FlagGroups.vue');
const GroupDescription = () => import(/* webpackChunkName: "groups" */ '../views/groups/GroupDescription.vue');
const AppFlags = () => import(/* webpackChunkName: "report" */ '../views/reports/AppFlags.vue');

const routes = [
    {
        path: '/',
        redirect: '/flags',
    },
    {
        path: '/flags/:flagName',
        name: 'flags.flag',
        component: FeatureFlagDescription,
    },
    {
        path: '/logout',
        name: 'logout',
    },
    {
        path: '/flags',
        name: 'flags',
        component: FeatureFlags,
    },
    {
        path: '/app-flags',
        name: 'app-flags',
        component: AppFlags,
    },
    {
        path: '/report/threshold',
        name: 'thresholdReport',
        component: ThresholdReport,
    },
    {
        path: '/report/recentlyCreated',
        name: 'recentlyCreatedReport',
        component: RecentlyCreatedReport,
    },
    {
        path: '/report/recentlyChanged',
        name: 'recentlyChangedReport',
        component: RecentlyChangedReport,
    },
    {
        path: '/groups',
        name: 'groups',
        component: FlagGroups,
    },
    {
        path: '/groups/:groupName',
        name: 'groups.group',
        component: GroupDescription,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/flags',
    },
];

export default routes;
