<template>
    <nav :class="{ open: navOpen }">
        <div>
            <RouterLink to="/flags" class="link">
                Feature Flags
            </RouterLink>
            <RouterLink to="/groups" class="link">
                Feature Flag Groups
            </RouterLink>
            <RouterLink to="/app-flags" class="link">
                Flags in an app
            </RouterLink>
            <RouterLink to="/report/threshold" class="link">
                100% Threshold Report
            </RouterLink>
            <RouterLink to="/report/recentlyCreated" class="link">
                Recently Created Flags Report
            </RouterLink>
            <RouterLink to="/report/recentlyChanged" class="link">
                Recently Changed Flags Report
            </RouterLink>
        </div>

        <div class="logout">
            <RouterLink to="/logout" class="link">
                Logout
            </RouterLink>
        </div>
    </nav>
</template>

<script>
import { computed } from 'vue';
import { useGlobalStore } from '@/stores/global';

export default {
    setup() {
        const globalStore = useGlobalStore();

        return {
            navOpen: computed(() => globalStore.navOpen),
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    nav {
        @include transition(transform);
        @include overflowY;

        min-width: $menu-width;
        max-width: $menu-width;
        justify-content: flex-start;

        background-color: $color-white;
        overflow-x: hidden;
        position: fixed;
        top: $nav-height;
        bottom: 0;
        z-index: 4;
        display: flex;
        flex-direction: column;

        &.open {
            transform: translateX(0);
        }

        &:not(.open) {
            transform: translateX(-100%);
        }

        @media($small) {
            min-width: 100%;
            max-width: 100%;
        }

        @media($medium) {
            box-shadow: $elevation-z1;
        }

        .link {
            display: block;
            color: $color-black;
            padding: $spacing-200 $spacing-300;
            font-size: $font-size-md;
            width: 100%;

            &:hover {
                background-color: $color-gray-lightest;
                text-decoration: none;
            }
        }
    }
</style>
