<template>
    <main id="main-content">
        <NavHeader v-if="authorizedRoute" />
        <DsErrorBannerContainer />
        <NavMenu v-if="authorizedRoute" />

        <section class="view-container">
            <RouterView />
        </section>
    </main>

    <DsToastContainer />
</template>

<script>
import NavHeader from '@/components/NavHeader';
import NavMenu from '@/components/NavMenu';
import { useGlobalStore } from '@/stores/global';

export default {
    components: {
        NavHeader,
        NavMenu,
    },

    setup() {
        useGlobalStore().setHistoryCount(window.history.length);
    },

    computed: {
        authorizedRoute() {
            return !['logout'].includes(this.$route.name);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .view-container {
        padding: $spacing-200;
        margin: 0 auto;
        max-width: 80rem;
    }
</style>
