<template>
    <header>
        <div class="hamburger-wrapper" @click="toggleNav" @keydown.enter="toggleNav">
            <DsIcon name="hamburger" />
        </div>

        <div class="user-wrapper">
            <div class="username">
                Logged in as: <strong>{{ userName }}</strong>
            </div>

            <div class="logo-wrapper">
                <DsLogo icon-only />
            </div>
        </div>
    </header>
</template>

<script>
import { computed } from 'vue';
import { useGlobalStore } from '@/stores/global';
import { useAuthStore } from '@/stores/auth';

export default {
    setup() {
        const authStore = useAuthStore();

        return {
            globalStore: useGlobalStore(),
            user: computed(() => authStore.user),
        };
    },

    computed: {
        userName() {
            return this.user?.email;
        },
    },

    methods: {
        toggleNav() {
            this.globalStore.toggleNav();
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    header {
        height: $nav-height;
        background-color: $color-white;
        border-bottom: 1px solid $color-gray-lighter;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo-wrapper {
        padding: $spacing-200;
        width: px-to-rem(70px);
    }

    .username {
        font-size: $font-size-lg;
        @include margin-end($spacing-200);
    }

    .user-wrapper {
        display: flex;
        align-items: center;
    }

    .hamburger-wrapper {
        padding: 0 $spacing-200;
        height: $nav-height;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-lighter;
        }
    }
</style>
