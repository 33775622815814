import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { useGlobalStore } from '@/stores/global';
import { useAuthStore } from '@/stores/auth';

const setTitle = () => {
    setTimeout(() => {
        document.title = `Feature Flag Service (${window.__KEAP__.VUE_APP_ENV})`;
    });
};

export const createRouter = () => {
    const router = createVueRouter({
        history: createWebHistory(),
        routes,
    });

    router.beforeEach((to, _, next) => {
        const authStore = useAuthStore();

        if (to.query.ticket) {
            const loggedIn = authStore.casJwt;

            if (loggedIn) {
                // Remove ticket from URL so that it doesn't end up on a referer header somewhere
                window.history.replaceState(null, null, window.location.pathname);

                next(to.path);
            } else {
                // save CAS token on redirect
                authStore.login({ casJwt: to.query.ticket });
                next(to.path);
            }
        } else if (to.name === 'logout') {
            authStore.logout();
            const redirectUrl = new URL(window.location.href);

            window.location.assign(`${window.__KEAP__.VUE_APP_CAS_URL}/logout?service=${encodeURIComponent(redirectUrl.toString())}`);
        } else if (!authStore.casJwt) {
            // redirect to CAS for login if we aren't authenticated
            const redirectUrl = new URL(window.location.href);

            window.location.assign(`${window.__KEAP__.VUE_APP_CAS_URL}/login?service=${encodeURIComponent(redirectUrl.toString())}`);
        } else {
            next();
        }
    });

    router.beforeResolve((to, _, next) => {
        setTitle(to);

        useGlobalStore().setNavOpen(false);

        next();
    });

    return router;
};

