import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
    state: () => ({
        navOpen: false,
        overlaysActive: 0,
        historyCount: 0,
    }),

    actions: {
        toggleNav() {
            this.navOpen = !this.navOpen;
        },

        setNavOpen(open) {
            this.navOpen = open;
        },

        setHistoryCount(count) {
            this.historyCount = count;
        },

        activateOverlay() {
            this.setOverlayActive(true);
        },

        deactivateOverlay() {
            this.setOverlayActive(false);
        },

        setOverlayActive(active) {
            if (active) {
                this.overlaysActive++;
            } else if (this.overlaysActive > 0) {
                this.overlaysActive--;
            }

            const className = 'overlay-active';

            setTimeout(() => {
                if (this.overlaysActive > 0) {
                    document.body.classList.add(className);
                } else {
                    document.body.classList.remove(className);
                }
            });
        },
    },
});
